define([], function () {

    function publish(name, subscribers, data) {
        subscribers.forEach(function (subscriber) {
            subscriber.apply(subscriber, data);
        });
    }

    function subscribeTo(name, subscribers, subscriber) {
        if (!subscriber) {
            throw new Error('Subscriber to event ' + name + ' must be a function, but was ' + subscriber);
        }

        var index = subscribers.indexOf(subscriber);
        if (index < 0)
            subscribers.push(subscriber);
        return index < 0;
    }

    function unsubscribeFrom(name, subscribers, subscriber) {
        var index = subscribers.indexOf(subscriber);
        if (index >= 0)
            subscribers.splice(index, 1);
        return index >= 0;
    }

    function addDispose(target, dispose) {
        if (!target.__disposeList__) {
            target.__disposeList__ = [];

            if (!target.dispose) {
                target.dispose = function() {
                    target.__disposeList__.forEach(function(call) {
                        call();
                    });
                }
            } else {
                var originalDispose = target.dispose;
                target.dispose = function () {
                    originalDispose.call(this);
                    target.__disposeList__.forEach(function (call) {
                        call();
                    });
                }
            }
        }

        target.__disposeList__.push(dispose);
    }

    function extendEvent(name, event) {
        event.subscribers = [];
        event.subscribeSubscribers = [];
        event.unsubscribeSubscribers = [];

        var extendedEvent = function () {
            if (arguments.length == 1 && typeof arguments[0] === "function") {
                extendedEvent.subscribeTo(arguments[0]);
            } else {
                extendedEvent.publish.apply(null, arguments);
            }
        }

        extendedEvent.subscribeTo = function (subscriber) {
            var wasNew = subscribeTo(name, event.subscribers, subscriber);
            if (wasNew) {
                publish(name + ".isSubscribedTo", event.subscribeSubscribers, [function () { subscriber.apply(subscriber, arguments); }]);
            }

            return {
                autoDispose: function(target) {
                    addDispose(target, function() { extendedEvent.dont(subscriber) });
                }
            }
        };

        extendedEvent.subscribeTo.onlyOnce = function(subscriber) {
            extendedEvent.subscribeTo(function onceOnlySubscriber() {
                subscriber.apply(this, arguments);
                extendedEvent.unsubscribeFrom(onceOnlySubscriber);
            });
        };

        extendedEvent.publish = function () {
            publish(name, event.subscribers, arguments);
        };

        extendedEvent.dont = function (subscriber) {
            if (unsubscribeFrom(name, event.subscribers, subscriber)) {
                publish(name + ".isUnsubscribedFrom", event.unsubscribeSubscribers);
            }
        };

        extendedEvent.unsubscribeFrom = extendedEvent.dont;

        extendedEvent.isSubscribedTo = function (subscriber) {
            subscribeTo(name + ".isSubscribedTo", event.subscribeSubscribers, subscriber);

            return {
                autoDispose: function (target) {
                    addDispose(target, function () { extendedEvent.isSubscribedTo.dont(subscriber) });
                }
            }
        };

        extendedEvent.isSubscribedTo.dont = function (subscriber) {
            unsubscribeFrom(name + ".isSubscribedTo", event.subscribeSubscribers, subscriber);
        };

        extendedEvent.isUnsubscribedFrom = function (subscriber) {
            subscribeTo(name + ".isUnsubscribedFrom", event.unsubscribeSubscribers, subscriber);

            return {
                autoDispose: function (target) {
                    addDispose(target, function () { extendedEvent.isUnsubscribedFrom.dont(subscriber) });
                }
            }
        };

        extendedEvent.isUnsubscribedFrom.dont = function (subscriber) {
            unsubscribeFrom(name + ".isUnsubscribedFrom", event.unsubscribeSubscribers, subscriber);
        };

        extendedEvent.toString = function () {
            return "[Event " + name + "]";
        }

        return extendedEvent;
    }

    function extend(events) {
        for (var i in events) {
            events[i] = extendEvent(i, events[i]);
        }
        return events;
    }

    function create(arg1, arg2) {
        if (arg2) {
            return extendEvent(arg1, arg2);
        } else {
            return extendEvent("anonymous event", arg1);
        }
    }

    return {
        extend: extend,
        create: create
    };

});