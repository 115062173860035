define([], function() {
    function call(func) {
        func();
    }

    function unsubscribe(event, reaction) {
        event.dont(reaction);
    }

    function subscribe(event, reaction) {
        event(reaction);
        var unsubscribe = event.dont.bind(null, reaction);
        this.eventSubscribers.push(unsubscribe);
        return unsubscribe;
    }

    function doAndDont(self, event) {
        var obj = subscribe.bind(self, event);
        obj.do = obj;
        obj.dont = unsubscribe.bind(null, event);
        return obj;
    }

    function whenSomething() {
        if (this.destroyed) throw new Error("This context has been destroyed!");

        if (arguments.length === 0) {
            var childContext = createContext();
            this.childContexts.push(childContext.destroyContext);
            return childContext.when;
        } else if (arguments.length === 1 && typeof arguments[0] === "function") {
            var result = doAndDont(this, arguments[0]);
            result.isSubscribedTo = doAndDont(this, arguments[0].isSubscribedTo);
            return result;
        } else if (arguments.length === 2 && typeof arguments[1] === "function") {
            return subscribe.call(this, arguments[0], arguments[1]);
        } else if (arguments.length === 3 && typeof arguments[2] === "function") {
            return addEventListener().call(this, arguments[0], arguments[1], arguments[2]);
        } else if (arguments.length === 4 && typeof arguments[2] === "function") {
            return addEventListener().call(this, arguments[0], arguments[1], arguments[2], arguments[3]);
        } else {
            throw new Error("unknown arguments", arguments);
        }
    }

    function removeEventListener(target, event, listener) {
        target.removeEventListener(event, listener);
    }

    function addEventListener(target, event, listener, bubble) {
        target.addEventListener(event, listener, bubble);
        var removeListener = removeEventListener.bind(null, target, event, listener);
        this.eventListeners.push(removeListener);
        return removeListener;
    }

    function addTimeout(func, ms) {
        var timeout = window.setTimeout(func, ms);
        this.timeouts.push(removeTimeout.bind(null, timeout));
        return timeout;
    }

    function removeTimeout(timeout) {
        window.clearTimeout(timeout);
    }

    function addInterval(func, ms) {
        var interval = window.setInterval(func, ms);
        this.intervals.push(removeInterval.bind(null, interval));
        return interval;
    }

    function removeInterval(interval) {
        window.clearInterval(interval);
    }

    function thisIsDestroyed(reaction) {
        this.onDestroyListeners.push(reaction);
    }

    function destroyChildContexts() {
        this.childContexts.forEach(call);
        this.childContexts = [];
    }

    function destroyContext() {
        if (this.destroyed) return;
        this.eventSubscribers.forEach(call);
        this.onDestroyListeners.forEach(call);
        this.childContexts.forEach(call);
        this.eventListeners.forEach(call);
        this.timeouts.forEach(call);
        this.intervals.forEach(call);
        this.eventSubscribers = null;
        this.onDestroyListeners = null;
        this.childContexts = null;
        this.eventListeners = null;
        this.timeouts = null;
        this.intervals = null;
        this.destroyed = true;
    }

    function createContext() {
        var context = {
            destroyed: false,
            onDestroyListeners: [],
            childContexts: [],
            eventSubscribers: [],
            eventListeners: [],
            timeouts: [],
            intervals: []
        };

        var when = whenSomething.bind(context);

        when.thisIsDestroyed = thisIsDestroyed.bind(context);

        when.destroyChildContexts = destroyChildContexts.bind(context);

        when.addEventListener = addEventListener.bind(context);
        when.removeEventListener = removeEventListener;

        when.setTimeout = addTimeout.bind(context);
        when.clearTimeout = removeTimeout;

        when.setInterval = addInterval.bind(context);
        when.clearInterval = removeInterval;

        return {
            when: when,
            destroyContext: destroyContext.bind(context)
        };
    }

    return createContext().when;
});