define(["JScript/Common/cookieReader"], function (cookieReader) {
    var customerGroupPrivate = 40;
    var customerGroupEmployee = 50;

    var cookieData = cookieReader.getJwtTokenAsObject("Komplett.Caas.Session");
    var anonymousId = cookieReader.getCookieValue('Komplett.AnonymousId');

    if (!cookieData || !cookieData.exp) {
        return {
            displayName: "",
            isAnonymous: true,
            isProfileAuthenticated: false,
            isBusinessCustomer: false,
            isCrm: false,
            anonymousId: anonymousId
        };
    }

    var displayName = cookieData.cn ? cookieData.cn.split(" ")[0] : "";

    return {
        displayName: displayName,
        customerName: cookieData.cn,
        customerNumber: cookieData.aid,
        isAnonymous: cookieData.ann ? true : false,
        isProfileAuthenticated: cookieData.ia ? true : false,
        isBusinessCustomer: isBusinessCustomer(cookieData.cmg),
        profileId: cookieData.pid,
        isInLoyaltyClub: cookieData.kc ? true : false,
        isCrm: cookieData.cid ? true : false,
        visitGuid: cookieData.vg,
        komplettId: cookieData.kid,
        sessionId: cookieData.komidsid === undefined ? '' : cookieData.komidsid,
        anonymousId: anonymousId
    };

    function isBusinessCustomer(customerGroup) {
        return customerGroup !== undefined
            && customerGroup != customerGroupPrivate
            && customerGroup != customerGroupEmployee;
    }
});