define([], function () {
    /* eslint-disable no-param-reassign */
    function pushState(url, state) {
        url = pathnameWhenEmptyQueryString(url);
        history.pushState(state, "", url);
    }

    function replaceState(url, state) {
        url = pathnameWhenEmptyQueryString(url);
        history.replaceState(state, "", url);
    }
    /* eslint-enable no-param-reassign */

    function fallback() {
        // pushState is not supported by the current browser.
        // Instead of using hash navigation we do nothing
        // The only browser supported by us that doesn't
        // support push state is Internet Explorer 9
        // Only a tiny percentage of users is on IE9
    }

    var supported = "pushState" in history;

    function pathnameWhenEmptyQueryString(url) {
        if (url === "?") {
            return location.pathname;
        }
        return url;
    };

    return {
        pushState: supported ? pushState : fallback,
        replaceState: supported ? replaceState : fallback
    };
});