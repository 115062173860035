define("ksc-alert/events", ["komplett-pubsub"], function (pubsub) {
    var events = {
        alertAdded: function(message, alertLevel, receiver, defaultMessageWhenNotResolved) {},
        alertArrayAdded: function(alertArray) {},
        removeAlerts: function() {},
        removeAlert: function(receiver) {},
        toggleWrapperCss: function(cssClass, toggle) { }
    };

    return pubsub.extend(events);
});


define('ksc-alert/ViewModel', ['knockout', 'ksc-alert/events'], function (ko, events) {

    function ViewModel(initObject) {

        var self = this;
        var stickyCss = 'ksc-alert-sticky';

        this.alerts = ko.observableArray();    

        this.isOutOfView = ko.observable(false);
        this.isOutOfView.subscribe(function(value) {
            var css = self.wrapperCss();
            css[stickyCss] = isAnyAlertVisible() ? value : false;
            self.wrapperCss(css);
        });
        this.wrapperCss = ko.observable({});

        this.receiver = 'global';

        var areStringsEquivalentEvenWithSpecialChars = function(str1, str2) {
            return (str1.toString().localeCompare(str2.toString()) == 0);
        };

        var isTheRightReceiver = function(receiver) {
            var isMessageToNoSpecifiedReceiverAndCurrentReceiverIsGlobal = (!receiver && self.receiver.toLowerCase() === 'global');
            var isMessageToCurrentReceiver = (receiver && receiver.toLowerCase() === self.receiver.toLowerCase());

            return (isMessageToNoSpecifiedReceiverAndCurrentReceiverIsGlobal || isMessageToCurrentReceiver);
        };

        var alertWithAlreadyExistingMessage = function (message, level) {
            var returningItem = null;

            ko.utils.arrayFirst(self.alerts(), function (item) {
                if (areStringsEquivalentEvenWithSpecialChars(item.message, message) && item.level == level) {
                    returningItem = item;
                };
            });
 
            return returningItem;
        };
        
        var resolveMessageCode = function (code, defaultMessageWhenNotResolved) {
            if (initObject && initObject.messages && initObject.messages.hasOwnProperty(code)) {
                return initObject.messages[code];
            }
 
            if (defaultMessageWhenNotResolved) {
                return resolveMessageCode(defaultMessageWhenNotResolved);
            }

            return code;
        };

        var isAnyAlertVisible = function() {
            var visibleAlerts = ko.utils.arrayFilter(self.alerts(), function(alert) {
                return alert.isVisible();
            });

            return visibleAlerts.length > 0;
        }

        this.addAlert = function (message, level, receiver) {
            var toggleSticky = function() {
                if (!isAnyAlertVisible() && self.isOutOfView()) {
                    var css = self.wrapperCss();
                    css[stickyCss] = true;
                    self.wrapperCss(css);                
                }                
            }
            toggleSticky();

            var alreadyExistingAlert = alertWithAlreadyExistingMessage(message, level);

            if (alreadyExistingAlert != null) {
                alreadyExistingAlert.isVisible(true);
                alreadyExistingAlert.hasFocus(true);
                return;
            }

            self.alerts.push(
                {
                    message: message, 
                    level: level, 
                    isVisible: ko.observable(true),
                    hasFocus: ko.observable(true),
                    closeAlert: function () {
                        this.isVisible(false);
                        self.alertsChanged();
                    }, 
                    alertLevelClass: "ksc-alert-" + level, 
                    iconClass: "ksc-alert-" + level + "-icon",
                    receiver: receiver
                }
            );
            self.alertsChanged();
        };

        this.hasAlerts = ko.computed(function () {
            return self.alerts.length > 0;
        });

        this.toggleAlert = function (observable) {
            observable(!observable());
        };

        this.modelChanged = function (alertArray) {
            if (alertArray) {
                alertArray.forEach(function (alert) {
                    self.addAlert(alert.message, alert.level.toLowerCase(), alert.receiver);
                });
            }
        };

        this.removeAlerts = function () {
            self.alerts.removeAll();
        };
        
        this.removeAlert = function (receiver) {
            self.alerts.remove(function (alert) {
                return alert.receiver == receiver;
            }); 
        };

        this.alertsChanged = function() {
            if(!isAnyAlertVisible()) {
                var css = self.wrapperCss();
                css[stickyCss] = false;
                self.wrapperCss(css);                    
            }
        }
        

        events.alertAdded.subscribeTo(function (message, alertLevel, receiver, defaultMessageWhenNotResolved) {
            if (isTheRightReceiver(receiver)) {
                message = resolveMessageCode(message, defaultMessageWhenNotResolved);
        
                if (message && message.length > 0) {
                    self.addAlert(message, alertLevel, receiver);
                }
            }
        });

        events.alertArrayAdded.subscribeTo(function (alertArray) {
            self.modelChanged(alertArray);
        });

        events.removeAlerts.subscribeTo(function () {
            self.removeAlerts();
        });
    
        events.removeAlert.subscribeTo(function (receiver) {
            self.removeAlert(receiver);
        });

        events.toggleWrapperCss.subscribeTo(function(cssClass, toggle) {
            var css = self.wrapperCss();
            css[cssClass] = toggle;
            self.wrapperCss(css);            
        });

        init: {
            if (initObject) {
                if (initObject.alertArray) {
                    this.modelChanged(initObject.alertArray);
                }

                if (initObject.receiver) {
                    this.receiver = initObject.receiver;
                }

                if (initObject && initObject.stickyCss) {
                    stickyCss = initObject.stickyCss;
                }
            }
        }

    }

    return ViewModel;

});


define('ksc-alert',["ksc-alert/ViewModel",'ksc-alert/events'], function (ViewModel, events) {
    
    function init(options){
        
        var options = {
            someOption : options.someOption === undefined ? 'some value' : options.someOption
        };
        
        // Do som initialization or remove this dead init method and the access from the interface below.
    }

    return {
        init: init,
        ViewModel: ViewModel,
        events: events
    };
    
});


define("ksc-alert/custombindings", ["knockout"], function (ko) {
    ko.bindingHandlers.kscAlertSticky = {
        init: function(element, valueAccessor) {
            var unwrapAccessor = ko.unwrap(valueAccessor());
            var observable = unwrapAccessor.isOutOfView;

            var elementInitPos = element.offsetTop;

            if (ko.isObservable(observable)) {
                window.addEventListener("scroll", function () {
                    setTimeout(function() {
                        var scrollY = window.pageYOffset;
                        var scrollVal = observable();

                        if (scrollY >= elementInitPos) {
                            if (!scrollVal) {
                                observable(true);
                            }
                        } else {
                            if (scrollVal) {
                                observable(false);
                            }
                        }
                    }, 20);
                });
            }
        }
    }
});

