define(["JScript/Common/fastRequire", "JScript/Common/whenContext"], function (fastRequire, whenContext) {
    var lazyLoadWhenVisible = makeObserver();

    return {
        loadViewModel: function () {
            toArray(document.querySelectorAll("[data-viewmodel]"))
            .concat(toArray(document.querySelectorAll("[data-lazy-viewmodel]")))
            .forEach(function (target) {
                var viewModelName = target.getAttribute("data-viewmodel") || target.getAttribute("data-lazy-viewmodel");

                if (hasParentViewModelElement(target)) {
                    // preload the viewmodel because we know that we will need it soon
                    fastRequire(viewModelName);
                    return;
                }

                lazyLoadAndApplyViewModel(target, viewModelName, null, function apply(viewModel, when) {
                    target["@ViewModelName"] = viewModelName;
                    ko.utils.domData.clear(target);
                    ko.applyBindings(viewModel, target);
                    ko.utils.domNodeDisposal.addDisposeCallback(target, when.destroyChildContexts);
                });
            });
        },
        loadNestedViewModel: function (target, viewModelName, parentContext) {
            lazyLoadAndApplyViewModel(target, viewModelName, parentContext, function applyNested(viewModel, when) {
                target["@ViewModelName"] = viewModelName;
                var childContext = parentContext.createChildContext(viewModel);
                // forget about the binding on this element so we can apply a new binding to it
                ko.utils.domData.clear(target);
                ko.applyBindings(childContext, target);
                ko.utils.domNodeDisposal.addDisposeCallback(target, when.destroyChildContexts);
            });
        }
    };

    function lazyLoadAndApplyViewModel(target, viewModelName, bindingContext, apply) {
        if (target.hasAttribute("data-lazy-viewmodel")) {
            lazyLoadWhenVisible(target, function intersectionHandler() {
                loadAndApplyViewModel(target, viewModelName, bindingContext, apply);
            });
        } else {
            loadAndApplyViewModel(target, viewModelName, bindingContext, apply);
        }
    }

    function loadAndApplyViewModel(target, viewModelName, bindingContext, apply) {
        fastRequire(viewModelName, function fastRequireThen(ViewModel) {
            if (ViewModel === void 0 || ViewModel === null) {
                console.error("ViewModel is undefined for data-viewmodel=\"" + viewModelName + "\" on element",
                    target);
                return;
            }

            var model = getModel(target, bindingContext);
            var when = whenContext();
            var viewModel = typeof ViewModel === "object" ? ViewModel : new ViewModel(model, when());
            tryApply(apply, viewModel, when);
        }, function (e) {
            console.error("failed to load viewmodel(s) while loading viewModel " + viewModelName, e.requireModules);
            console.error(e);
        });
    }

    function getModel(target, bindingContext) {
        var initObjectName = target.getAttribute("data-initobject");
        if (initObjectName) {
            try {
                return JSON.parse(initObjectName);
            } catch (e) {
                console.error(e);
            }
        } else {
            return getComponentParamsFromCustomElement(target, bindingContext);
        }

        return undefined;
    }

    // this is stolen from the knockout sourcecode, and I had to copy it since it's not exposed as a public api
    function getComponentParamsFromCustomElement(elem, bindingContext) {
        var paramsAttribute = elem.getAttribute("data-params");

        if (!paramsAttribute) {
            return;
        }

        var params = ko.bindingProvider.instance.nativeBindingProviderInstance.parseBindingsString(paramsAttribute, bindingContext, elem, { "valueAccessors": true, "bindingParams": true });
        var rawParamComputedValues = Object.create(null);
        for (var paramName in params) { // eslint-disable-line guard-for-in
            rawParamComputedValues[paramName] = ko.computed(params[paramName], null, { disposeWhenNodeIsRemoved: elem });
        }
        var result = Object.create(null);
        for (paramName in rawParamComputedValues) { // eslint-disable-line guard-for-in
            var paramValueComputed = rawParamComputedValues[paramName];
            var paramValue = paramValueComputed.peek();
            // Does the evaluation of the parameter value unwrap any observables?
            if (!paramValueComputed.isActive()) {
                // No it doesn't, so there's no need for any computed wrapper. Just pass through the supplied value directly.
                // Example: "someVal: firstName, age: 123" (whether or not firstName is an observable/computed)
                result[paramName] = paramValue;
            } else {
                // Yes it does. Supply a computed property that unwraps both the outer (binding expression)
                // level of observability, and any inner (resulting model value) level of observability.
                // This means the component doesn't have to worry about multiple unwrapping. If the value is a
                // writable observable, the computed will also be writable and pass the value on to the observable.
                result[paramName] = ko.computed({
                    "read": function () { // eslint-disable-line no-loop-func
                        return ko.unwrap(paramValueComputed());
                    },
                    "write": ko.isWriteableObservable(paramValue) && function (value) { // eslint-disable-line no-loop-func
                        paramValueComputed()(value);
                    },
                    disposeWhenNodeIsRemoved: elem
                });
            }
        }
        return result; // eslint-disable-line consistent-return
    }

    function makeObserver() {
        if ('IntersectionObserver' in window) {
            var observed = new Map();
            var observer = new IntersectionObserver(function (entries) {
                entries.forEach(function (entry) {
                    // Old browsers don't have isIntersection, so we use intersectionRatio instead
                    // intersectionRatio isn't perfect, but it's good enough for us
                    if (entry.isIntersecting === undefined ? entry.intersectionRatio > 0 : entry.intersectionRatio) {
                        var callback = observed.get(entry.target);
                        if (callback) {
                            observer.unobserve(entry.target);
                            observed.delete(entry.target);
                            callback();
                        }
                    }
                });
            }, {
                threshold: 0.1,
                rootMargin: "200px 0px"
            });

            return function lazyLoadWhenVisible(element, callback) {
                observed.set(element, callback);
                observer.observe(element);

                ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
                    observer.unobserve(element);
                    observed.delete(element);
                });
            };
        } else {
            // IntersectionObserver not supported,
            // just load it straight away
            return function (element, callback) {
                callback();
            };
        }
    }

    function hasParentViewModelElement(element) {
        while ((element = element.parentNode) && typeof (element.hasAttribute) === "function") { // eslint-disable-line no-param-reassign, no-cond-assign
            if (element.hasAttribute("data-viewmodel") || element.hasAttribute("data-lazy-viewmodel")) {
                return true;
            }
        }

        return false;
    }

    function toArray(arrayLike) {
        return Array.prototype.slice.call(arrayLike, 0);
    }

    function tryApply(apply, viewModel, when) {
        try {
            apply(viewModel, when);
        } catch (e) {
            console.error(e);
        }
    }
});