define(["JScript/Common/requestContext"], function (requestContext) {
    return function CheckSessionViewModel(initObject) {
        if (!requestContext.isAnonymous && requestContext.sessionId.length !== 0) {
            var substitutedUtlWithSessionId = initObject.checkSessionUrl.replace('xxx', requestContext.sessionId);
            fetch(substitutedUtlWithSessionId, {
                credentials: "include",
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (isValid) {
                    if (!isValid) {
                        fetch("/login/semilogout", {
                            method: "POST",
                        })
                            .then(function () {
                                window.location.href = "/";
                            });
                    }
                });
        }
    };
});
