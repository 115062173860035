define(["knockout"], function (ko) {
    ko.bindingHandlers.kscAlertSticky = {
        init: function(element, valueAccessor) {
            var unwrapAccessor = ko.unwrap(valueAccessor());
            var observable = unwrapAccessor.isOutOfView;

            var elementInitPos = element.offsetTop;

            if (ko.isObservable(observable)) {
                window.addEventListener("scroll", function () {
                    setTimeout(function() {
                        var scrollY = window.pageYOffset;
                        var scrollVal = observable();

                        if (scrollY >= elementInitPos) {
                            if (!scrollVal) {
                                observable(true);
                            }
                        } else {
                            if (scrollVal) {
                                observable(false);
                            }
                        }
                    }, 20);
                });
            }
        }
    }
});