define(function () {
    function Helper() {
        this.triggerCustomEvent = triggerCustomEvent;

        function triggerCustomEvent(eventName, data) {
            var event;

            if (typeof CustomEvent === "function") {
                event = new CustomEvent(eventName, {detail: data});
            } else {
                event = document.createEvent("CustomEvent");
                event.initCustomEvent(eventName, true, true, data);
            }

            document.dispatchEvent(event);
        };
    }

    return new Helper();
});