define([
    "PageDependentComponents/RichRelevance/promoService",
    "PageDependentComponents/RichRelevance/events",
    "JScript/Common/helper",
    "JScript/Common/requestContext"
], function (
    promoService,
    events,
    helper,
    requestContext
) {
    function RichRelevanceIntegration(initObject, when) {
        var isEnabled = initObject.isEnabled;
        var requestData = initObject.pageData.requestContext;

        var initialized = false;
        var previousResult = null;

        var applyNewPageData = function (pageData) {
            when(events.recsLoaded).isSubscribedTo.dont(publishRecs);
            when(events.promosLoaded).isSubscribedTo.dont(publishPromos);
            when(events.navLoaded).isSubscribedTo.dont(publishNavs);

            populateRequestData(pageData);

            getPlacementsFromService(pageData)
                .then(function (result) {
                    if (!result) {
                        result = {
                            promos: [],
                            placements: []
                        };
                    }

                    runRichRelevanceExperianceBrowser(result);

                    promoService.setTargetAndReportUrls(result.promos || []);
                    previousResult = result;

                    publishRecs(events.recsLoaded.publish);
                    publishPromos(events.promosLoaded.publish);
                    publishNavs(events.navLoaded.publish);

                    when(events.recsLoaded).isSubscribedTo(publishRecs);
                    when(events.promosLoaded).isSubscribedTo(publishPromos);
                    when(events.navLoaded).isSubscribedTo(publishNavs);
                });
        };

        function publishRecs(publish) {
            return publish(previousResult.placements || []);
        }

        function publishPromos(publish) {
            return publish(previousResult.promos || []);
        }

        function publishNavs(publish) {
            return publish(previousResult.promos || []);
        }

        function pageDataUpdated (value) {
            initObject = value;
            events.reset.publish();
            applyNewPageData(value.pageData);
        };

        function addedToCart(sku) {
            if (isEnabled) {
                fetch("/rr/addtocart",
                    {
                        method: "POST",
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            sku: sku,
                            requestContext: requestData
                        }),
                        credentials: "same-origin"
                    });
            }
        };

        function getPlacementsFromService(pageData) {
            if (!isEnabled) {
                return Promise.resolve(null);
            }

            // we should skip RR call when promos and recs are empty except cart page or
            // recs are empty and promos has only one item (only history is added from backend) (we don't set this rule for category and search)
            if ((pageData.promoPlacementIds.length === 0 && pageData.recsPlacementIds.length === 0 && window.location.pathname !== "/cart") ||
                (pageData.promoPlacementIds.length === 0 && pageData.recsPlacementIds.length === 1 && pageData.recsPlacementIds[0] === "history"
                && pageData.pageType !== "Category" && pageData.pageType !== "Search")) {
                return Promise.resolve(null);
            }

            if (!initialized) {
                initialized = true;
                helper.triggerCustomEvent("richRelevanceInitialized", { initObject: initObject, mutateInitObject: pageDataUpdated });
            }

            var cfrad = new URLSearchParams(window.location.search).get("cfrad");

            if (cfrad) pageData.cfrad = cfrad;

            return fetch("/rr/GetPlacementsAndPromos", {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(pageData),
                credentials: "same-origin"
            }).then(function (response) {
                return response.ok ? response.json() : null;
            });
        }

        function populateRequestData(pageData) {
            pageData.requestContext.isCrm = requestContext.isCrm;
            pageData.requestContext.isBusinessCustomer = requestContext.isBusinessCustomer;
            pageData.requestContext.anonymous = requestContext.isAnonymous;
            pageData.requestContext.showAsNetPrices = requestContext.isBusinessCustomer;
            pageData.requestContext.komplettId = requestContext.komplettId;
            pageData.requestContext.accountId = requestContext.customerNumber;
            pageData.requestContext.anonymousId = requestContext.anonymousId;
            pageData.requestContext.visitGuid = requestContext.visitGuid;
        }

        function runRichRelevanceExperianceBrowser(result) {
            var script;

            if (result.rrAnnotations) {
                if (window.rr_annotations_array) {
                    window.rr_annotations_array.push(result.rrAnnotations);
                }
                else {
                    window.rr_annotations_array = [result.rrAnnotations];
                }

                if (window.rr_experience_browser) {
                    window.rr_experience_browser.start();
                }
                else {
                    script = document.createElement("script");
                    script.src = "//cdn.richrelevance.com/dashboard/applications/cfrad/dist/index.min.js";
                    script.async = true;
                    document.getElementsByTagName("head")[0].appendChild(script);
                }
            }
        }

        when.addEventListener(document, "cartInitialized", function (e) {
            if (e && e.detail) {
                initObject.pageData.cartModel = {
                    productsInCart: e.detail.productsIds
                };

                initObject.pageData.pageType = "Cart";
                initObject.pageData.recsPlacementIds = e.detail.placementIds;

                applyNewPageData(initObject.pageData);
            }
        });

        when.addEventListener(document, "cartItemsChanged", function (e) {
            if (e && e.detail) {
                initObject.pageData.cartModel = {
                    productsInCart: e.detail
                };

                applyNewPageData(initObject.pageData);
            }
        });

        init: {
            applyNewPageData(initObject.pageData);
            when(events.updatedPageData).do(pageDataUpdated);
            when(events.addToCart).do(addedToCart);
        }
    }

    return RichRelevanceIntegration;
});