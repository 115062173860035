define(["JScript/Common/AtobUtf8", "JScript/Common/TextDecoder"], function (base64js, TextDecoderLite) {

    var decoder = new (window.TextDecoder || TextDecoderLite)("utf-8");

    function getCookieValue(a) {
        var b = document.cookie.match("(^|;)\\s*" + a + "\\s*=\\s*([^;]+)");
        return b ? b.pop() : "";
    };

    function getParameterFromCookieByName(parameter, cookieName) {
        var name = parameter.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]*" + name + "(=([^&#]*)|&|#|$)");
        var results = regex.exec(getCookieValue(cookieName));
        if (!results) {
            return null;
        }
        if (!results[2]) {
            return "";
        }
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    };

    function b64EncodeUnicode(str) {
        // first we use encodeURIComponent to get percent-encoded UTF-8,
        // then we convert the percent encodings into raw bytes which
        // can be fed into btoa.
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
            function toSolidBytes(match, p1) {
                return String.fromCharCode("0x" + p1);
            }));
    }

    function b64DecodeUnicode(str) {
        var bytes = base64js.toByteArray(str);
        return decoder.decode(bytes);
    }

    //Putting rightPad here in case it is removed from the internet (it has happened before!)
    function rightPad(str) {
        switch (str.length % 4) {
            case 1:
                return str + "===";
            case 2:
                return str + "==";
            case 3:
                return str + "=";
            default:
                return str;
        }
    }

    return {
        getJsonCookieValue: function (cookieName) {
            var cookie = getCookieValue(cookieName);
            return cookie ? JSON.parse(cookie) : null;
        },
        readCookieValueAsObject: function (parameter, cookieName) {
            return JSON.parse(getParameterFromCookieByName(parameter, cookieName));
        },
        getBase64CookieAsObject: function (cookieName) {
            var cookie = getCookieValue(cookieName);
            if (cookie === "") {
                return {};
            }
            return JSON.parse(b64DecodeUnicode(cookie));
        },
        setBase64CookieFromObject: function (cookieName, data, expirationDate) {
            var cookie = cookieName + "=" + b64EncodeUnicode(JSON.stringify(data)) + "; Path=/";
            if (expirationDate) {
                cookie += "; Expires=" + expirationDate.toGMTString();
            }
            document.cookie = cookie;
        },
        getJwtTokenAsObject: function (cookieName) {
            var cookie = getCookieValue(cookieName);
            if (cookie === "") {
                return {};
            }

            try {
                var base64Url = cookie.split(".")[1];
                var base64 = base64Url.replace("-", "+").replace("_", "/");
                return JSON.parse(b64DecodeUnicode(rightPad(base64)));
            } catch (err) {
                return {};
            }
        },
        getCookieValue: function (cookieName) { return getCookieValue(cookieName) }
    };
});