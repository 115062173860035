define(["knockout"], function (ko) {
  ko.bindingHandlers.positionCalculator = {
    update: function (element, valueAccessor) {
      var object = valueAccessor();
      var actionElement = object.actionElement,
        topLeftObservable = object.topLeft,
        open = object.open,
        screenIsXs = object.screenIsXs,
        callback = object.callback;
      contentRendered = object.contentRendered;
      neverOpenUpwards = object.neverOpenUpwards;

      var doResizing = function () {
        ko.bindingHandlers.positionCalculator.resize(
          actionElement,
          topLeftObservable,
          element,
          screenIsXs,
          neverOpenUpwards,
          callback
        );
      };

      window.removeEventListener("resize", doResizing);

      if (contentRendered && contentRendered()) {
        doResizing();
      }

      if (open()) {
        doResizing();
        window.addEventListener("resize", doResizing);
      }
    },
    resize: function (
      actionElement,
      topLeftObservable,
      element,
      isScreenXs,
      neverOpenUpwards,
      callback
    ) {
      var isIE11 = !!window.MSInputMethodContext;

      var elementPosition,
        elementViewportPosition,
        windowWidth = window.innerWidth,
        windowHeight = isIE11 ? window.innerHeight + 130 : window.innerHeight,
        topLeft = {},
        factor = 0.92,
        zIndex = element.style.zIndex,
        screenIsXs = isScreenXs();

      element.style = "";
      element.style.zIndex = zIndex;

      if (screenIsXs) {
        element.style.maxHeight = windowHeight * factor;
      }

      element.style.maxWidth = windowWidth * factor;

      if (actionElement) {
        elementViewportPosition = actionElement.getBoundingClientRect();
        elementPosition = {
          top: elementViewportPosition.top + window.pageYOffset,
          left: elementViewportPosition.left + window.pageXOffset,
        };

        if (!screenIsXs) {
          //Its more space on the left side
          if (
            elementViewportPosition.left >
            windowWidth - elementViewportPosition.left
          ) {
            topLeft.left = elementPosition.left + actionElement.offsetWidth;
            element.style.right = 0;
          } else {
            topLeft.left = elementPosition.left;
            element.style.left = 0;
          }
          //Its more space on the top
          if (
            elementViewportPosition.top >
              windowHeight - elementViewportPosition.top &&
            element.offsetHeight < elementViewportPosition.bottom &&
            !neverOpenUpwards
          ) {
            topLeft.top = elementPosition.top + actionElement.offsetHeight;
            element.style.bottom = 0;
          } else {
            topLeft.top = elementPosition.top;
            element.style.top = 0;
          }
          topLeftObservable(topLeft);
        }
      } else {
        var elementHeight =
          element.offsetHeight < 73 ? 350 : element.offsetHeight;
        elementPosition = {
          top: Math.max(
            0,
            window.pageYOffset + (windowHeight - elementHeight) / 2
          ),
          left: Math.max(0, (windowWidth - element.offsetWidth) / 2),
        };
        if (!screenIsXs) {
          topLeft.left = elementPosition.left;
          element.style.left = 0;
          topLeft.top = elementPosition.top;
          element.style.top = 0;

          topLeftObservable(topLeft);
        }
      }

      if (typeof callback === "function") {
        callback();
      }
    },
  };

  ko.bindingHandlers.uboMove = {
    update: function (element, valueAccessor) {
      var object = valueAccessor();
      var topLeft = object && object.topLeft ? object.topLeft() : undefined;
      if (topLeft) {
        element.style.top = topLeft.top + "px";
        element.style.left = topLeft.left + "px";
      }
    },
  };

  var _keyTrapWrapper = {};

  ko.bindingHandlers.uboKeyTrap = {
    init: function () {
      // Those spans are used to trap tabbing inside modal
      // Can be improved in future
      _keyTrapWrapper.beforeSpan = document.createElement("SPAN");
      _keyTrapWrapper.beforeSpan.tabIndex = "0";
      _keyTrapWrapper.beforeFocus = document.createElement("SPAN");
      _keyTrapWrapper.beforeFocus.tabIndex = "0";
      _keyTrapWrapper.afterSpan = document.createElement("SPAN");
      _keyTrapWrapper.afterSpan.tabIndex = "0";
      _keyTrapWrapper.afterFocus = document.createElement("SPAN");
      _keyTrapWrapper.afterFocus.tabIndex = "0";
    },
    update: function (element, valueAccessor) {
      var value =
        typeof valueAccessor === "function"
          ? valueAccessor()
          : valueAccessor().keyTrap();

      if (ko.unwrap(value)) {
        var selectedObj =
          typeof valueAccessor().scope === "undefined"
            ? element
            : document.querySelector(valueAccessor().scope);
        selectedObj.insertAdjacentElement(
          "beforebegin",
          _keyTrapWrapper.beforeSpan
        );
        selectedObj.insertAdjacentElement(
          "beforebegin",
          _keyTrapWrapper.beforeFocus
        );

        selectedObj.insertAdjacentElement(
          "afterend",
          _keyTrapWrapper.afterSpan
        );
        selectedObj.insertAdjacentElement(
          "afterend",
          _keyTrapWrapper.afterFocus
        );
        _keyTrapWrapper.afterSpan.addEventListener("focus", function () {
          _keyTrapWrapper.beforeFocus.focus();
        });
        _keyTrapWrapper.beforeSpan.addEventListener("focus", function () {
          _keyTrapWrapper.afterFocus.focus();
        });

        if (typeof valueAccessor().scope === "undefined") {
          _keyTrapWrapper.beforeFocus.focus();
        }
      }
    },
    renderedHandler: function () {
      _keyTrapWrapper.beforeFocus.focus();
    },
  };

  ko.bindingHandlers.uboOnKeyUp = {
    update: function (element, valueAccessor) {
      var object = ko.utils.unwrapObservable(valueAccessor());
      var handler = function (event) {
        if (event.keyCode == object.key) {
          object["do"](event);
        }
        document.removeEventListener("keyup", handler);
      };
      if (ko.utils.unwrapObservable(object.when)) {
        document.addEventListener("keyup", handler);
      }
    },
  };

  ko.bindingHandlers.uboContentRendered = {
    update: function (element, valueAccessor) {
      setTimeout(function () {
        valueAccessor()(true);
      }, 1);
    },
  };

  ko.bindingHandlers.uboNonEmptyText = {
    update: function (element, valueAccessor) {
      var text = ko.utils.unwrapObservable(valueAccessor());
      if (text) {
        element.innerText = text;
      }
    },
  };
});
