define([], function () {
    function TextDecoderLite() {
    }

    TextDecoderLite.prototype.decode = function (bytes) {
        return utf8Slice(bytes, 0, bytes.length);
    };

    function utf8Slice(buf, start, end) {
        var res = "";
        var tmp = "";
        end = Math.min(buf.length, end || Infinity);
        start = start || 0;

        for (var i = start; i < end; i++) {
            if (buf[i] <= 0x7F) {
                res += decodeUtf8Char(tmp) + String.fromCharCode(buf[i]);
                tmp = "";
            } else {
                tmp += "%" + buf[i].toString(16);
            }
        }

        return res + decodeUtf8Char(tmp);
    }

    function decodeUtf8Char(str) {
        try {
            return decodeURIComponent(str);
        } catch (err) {
            return String.fromCharCode(0xFFFD); // UTF 8 invalid char
        }
    }

    return TextDecoderLite;
});
