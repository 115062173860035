define(["komplett-pubsub"], function (pubsub) {
    /* eslint-disable no-unused-vars */
    var events = {
        updatedPageData: function (pageData) { },
        reset: function () { },
        addToCart: function (sku) { },
        recsLoaded: function (recs) {},
        promosLoaded: function (promos) { },
        navLoaded: function (navs) {}
    };
    /* eslint-enable no-unused-vars */
    return pubsub.extend(events);
});