define([], function () {
    var map = Object.create(null);

    function fastRequire(dep, onload, onerror) {
        if (dep in map) {
            map[dep] = map[dep].then(delay(onload), rethrow(onerror));
        } else {
            map[dep] = new Promise(function (resolve, reject) {
                require([dep], resolve, reject);
            }).then(wrap).then(delay(onload), rethrow(onerror));
        }
    }

    function delay(cb) {
        if (!cb) {
            return function noCb(wrapper) {
                return wrapper;
            };
        }

        return function delayed(wrapper) {
            cb(wrapper.value);
            var now = Date.now();
            if (now - wrapper.start > 150) {
                wrapper.start = now;
                return new Promise(function onRaf(resolve) {
                    window.requestAnimationFrame(resolve);
                }).then(function onRafThen() {
                    return wrapper;
                });
            }
            return wrapper;
        };
    }

    function wrap(value) {
        return {
            value: value,
            start: Date.now()
        };
    }

    function rethrow(onerror) {
        if (!onerror) {
            return function onOnError(error) {
                return Promise.reject(error);
            };
        }

        return function onOnError(error) {
            onerror(error);
            return Promise.reject(error);
        };
    }

    return fastRequire;
});