define("ksc-ubo/events",["komplett-pubsub"], function(pubsub) {
    var events = {
        uboOpened: function () {},
        uboClosed: function () {}
    };

    return pubsub.extend(events);
});


define('ksc-ubo/UboViewModel', ["knockout", "ksc-ubo/events", "ksc-breakpoint-notifier"], function (ko, events, kscBreakpointNotifier) {

    function UboViewModel(uboService,contentModel) {
        var self = this;

        this.id =                uboService.getNewId();
        this.contentViewModel =  contentModel.contentViewModel;
        this.actionElement =     contentModel.actionElement;
        this.topLeft =           ko.observable();
        this.header =            contentModel.header || "";
        this.isOkCancelDialog  = contentModel.isOkCancelDialog || false;
        this.htmlOutput =        ko.observable();
        this.rendered =          ko.observable(false);
        this.contentRendered =   ko.observable(false);
        this.componentId =       'ubo-content-' + self.id;
        this.uboService =        uboService,        
        this.zIndex =            uboService.getLatestZIndex();
        this.open =              ko.observable(true);
        this.screenIsXs = ko.observable(false);
        this.hasActionElement = ko.observable(true);
        this.neverOpenUpwards = contentModel.neverOpenUpwards;

        this.uboClasses = ko.computed(function () {
            var uboClasses = contentModel.uboClass;
            if (!self.hasActionElement()) {
                if (uboClasses) {
                    uboClasses += " centerUbo";
                } else {
                    uboClasses = "centerUbo";
                }
            }
            return uboClasses;
        }, this);

        this.close = function() {
            self.open(false);
            uboService.closeSpecific(self);
        };

        this.closeOnBackgroundClick = function() {
            if (!self.isOkCancelDialog) {
                self.close();
            }
        };

        this.closeLatest = function() {
            uboService.closeLatest();
        };

        this.htmlOutput.subscribe(function (value) {
            if (value) {
                ko.components.register(self.componentId, {
                viewModel: function() {
                    if(!self.screenIsXs()) {
                        ko.bindingHandlers.uboKeyTrap.renderedHandler(); 
                    }
                    
                    try {
                        return new self.contentViewModel();
                    } catch (err) {
                        return self.contentViewModel;
                    }
                },
                template: value
                });
            }
        });

        kscBreakpointNotifier.events.isXs.subscribeTo(function (isXs) {
            self.screenIsXs(isXs);
        });
    }

    return UboViewModel;
});


define("ksc-ubo/UboContentModel", function () {

    function UboContentModel() {
        this.header = null;
        this.contentViewModel = null;
        this.uboClass = null;
        this.actionElement = null;
        this.backgroundOverlay = true;
        this.maxWidth = null;
        this.maxHeight = null;
        this.neverOpenUpwards = false;
    }

    return UboContentModel;
});


define("ksc-ubo/UboSyncContentModel", [
        "ksc-ubo/UboContentModel"
    ], function (UboContentModel) {

    function UboSyncContentModel() {
        Object.assign(this, new UboContentModel());
        this.content = null;
    }

    return UboSyncContentModel;
});


define("ksc-ubo/UboOkCancelViewModel", [
        'ksc-ubo/UboViewModel'
    ], function (UboViewModel) {

    function UboOkCancelViewModel(uboService, contentModel) {
        var self = this;
        Object.assign(this, new UboViewModel(uboService, contentModel));

        this.acceptCallback = contentModel.acceptCallback,
        this.cancelCallback = contentModel.cancelCallback,
        this.acceptButtonString = contentModel.acceptButtonString,
        this.cancelButtonString = contentModel.cancelButtonString,

        this.cancel = function () {
            if (self.cancelCallback) {
                self.cancelCallback();
            };

            uboService.closeSpecific(self);
        }

        this.accept = function () {
            self.acceptCallback();
            uboService.closeSpecific(self);
        }
    }

    return UboOkCancelViewModel;
});


define("ksc-ubo/uboService", [
    "knockout",
    "ksc-ubo/UboViewModel",
    "ksc-ubo/UboOkCancelViewModel",
    "ksc-ubo/events"
    ], function (ko, UboViewModel, UboOkCancelViewModel, events) {

    function UboService() {
        var self = this,
            counter = 0;

        this.lastDeletedVm = null;
        this.ubos = ko.observableArray([]);
        this.uboClass = ko.observable("ubo");

        var defaultLoaderIndicator = '<div class="throbber"><svg class="circular"><circle class="path" cx="15" cy="15" r="12"/></svg></div>';

        var aSyncCall = function (url, successCallback) {
            var delayHandler = setTimeout(function () {}, 500);

            fetch(url).then(function (response) {
                return response.text()
            })
            .then(function(html) {
                clearTimeout(delayHandler);
                successCallback(html);
            });
        };

        var publishUboOpened = function() {
            if (self.ubos().length == 0) {
                events.uboOpened.publish();
            }
        }

        this.createUbo = function (html, contentModel) {
            var uboViewModel = new UboViewModel(self, contentModel);
            publishUboOpened();
            uboViewModel.htmlOutput(html);
            if (contentModel.actionElement == null) {
                uboViewModel.hasActionElement(false);
            }
            self.ubos.push(uboViewModel);
            if (contentModel.uboClass) {
                self.uboClass(contentModel.uboClass + " ubo");
            } else {
                self.uboClass("ubo");
            }
            uboViewModel.rendered(true);
        };

        this.getNewId = function () {
            return counter++;
        };

        this.getLatestZIndex = function () {
            return 27640 + counter;
        }

        this.openAsync = function (aSyncContentModel) {
            // create stub UBO with loader inside
            self.createUbo(aSyncContentModel.loader || defaultLoaderIndicator, {
                actionElement: aSyncContentModel.actionElement,
                uboClass: aSyncContentModel.uboClass,
            });

            var callback = function (html) {
                self.closeLatest(); // close stub UBO
                self.createUbo(html, aSyncContentModel);
            }
            aSyncCall(aSyncContentModel.url, callback);
        };

        this.openSync = function (syncContentModel) {
            self.createUbo(syncContentModel.content, syncContentModel);
        };

        this.openOKCancelSync = function (okCancelContentModel) {
            var uboViewModel = new UboOkCancelViewModel(self, okCancelContentModel);
            publishUboOpened();
            uboViewModel.htmlOutput(okCancelContentModel.content);
            self.ubos.push(uboViewModel);
        };

        this.openOKCancelAsync = function (okCancelContentModel) {
            // create stub UBO with loader inside
            self.createUbo(okCancelContentModel.loader || defaultLoaderIndicator, {
                actionElement: okCancelContentModel.actionElement,
                uboClass: okCancelContentModel.uboClass,
            });

            var callback = function (html) {
                self.closeLatest(); // close stub UBO
                okCancelContentModel.content = html;
                self.openOKCancelSync(okCancelContentModel);
            }
            aSyncCall(okCancelContentModel.url, callback);
        };

        this.closeLatest = function () {
            var removedUboViewModel = self.ubos()[self.ubos().length - 1];
            self.closeSpecific(removedUboViewModel);
        };

        this.closeAllUbos = function () {
            var length = self.ubos().length;
            for (var i = length; i > 0; i--) {
                var removedUboViewModel = self.ubos()[self.ubos().length - 1];
                self.closeSpecific(removedUboViewModel);
            }
        };

        this.closeSpecific = function (viewModel) {

            if (self.ubos().length==1) {
                events.uboClosed.publish();
            }
            if (viewModel) {
                self.lastDeletedVm = viewModel;
                self.ubos.remove(viewModel);
                ko.components.unregister(viewModel.componentId);
                if (viewModel.actionElement) viewModel.actionElement.focus();
            }
        }
    }

    return new UboService();
});


define("ksc-ubo/UboOKCancelContentModel", [
        'ksc-ubo/UboSyncContentModel'
    ], function (UboSyncContentModel) {

    function uboOKCancelContentModel() {
        Object.assign(this, new UboSyncContentModel());

        this.isOkCancelDialog = true;

        this.acceptCallback = null;
        this.cancelCallback = null;
        this.acceptButtonString = null;
        this.cancelButtonString = null;
    }

    return uboOKCancelContentModel;
});


define("ksc-ubo/UboAsyncContentModel", [
        "ksc-ubo/UboContentModel"
    ], function (UboContentModel) {

    function UboAsyncContentModel() {
        Object.assign(this, new UboContentModel());
        this.url = null;
    }

    return UboAsyncContentModel;
});


define("ksc-ubo", [
    "ksc-ubo/events",
    "ksc-ubo/UboAsyncContentModel",
    "ksc-ubo/UboContentModel",
    "ksc-ubo/UboOKCancelContentModel",
    "ksc-ubo/UboOkCancelViewModel",
    "ksc-ubo/uboService",
    "ksc-ubo/UboSyncContentModel",
    "ksc-ubo/UboViewModel"], 
    function (
        events, 
        uboAsyncContentModel,
        uboContentModel,
        uboOkCancelContentModel,
        uboOkCancelViewModel,
        uboService,
        uboSyncContentModel,
        uboViewModel) {

    return {
        events: events,
        uboAsyncContentModel: uboAsyncContentModel,
        uboContentModel: uboContentModel,
        uboOkCancelContentModel: uboOkCancelContentModel,
        uboOkCancelViewModel: uboOkCancelViewModel,
        uboService: uboService,
        uboSyncContentModel: uboSyncContentModel,
        uboViewModel: uboViewModel
    };
    
});

