define("ksc-breakpoint-notifier/ScreenSize", function () {

    function ScreenSize(fullResponsive) {

        var screenSizes = function () {
            if (fullResponsive) {
                return {
                    screenXs: 480,
                    screenSm: 600,
                    screenMd: 992,
                    screenLg: 1300
                }
            } else {
                return {
                    screenXs: 1,
                    screenSm: 2,
                    screenMd: 3,
                    screenLg: 10000
                }
            }
        };


        var screenSize = screenSizes();


        this.isXs = function () {
            return window.matchMedia ? window.matchMedia("screen and (max-width: " + (screenSize.screenSm - 1) + "px)").matches : false;
        };

        this.isSm = function () {
            return window.matchMedia ? window.matchMedia("screen and (min-width: " + screenSize.screenSm + "px) and (max-width: " + (screenSize.screenMd - 1) + "px)").matches : false;
        };

        this.isMd = function () {
            return window.matchMedia ? window.matchMedia("screen and (min-width: " + screenSize.screenMd + "px) and (max-width: " + (screenSize.screenLg - 1) + "px)").matches : true;
        };

        this.isLg = function () {
            return window.matchMedia ? window.matchMedia("screen and (min-width: " + screenSize.screenLg + "px)").matches : false;
        };
    }

    return ScreenSize;
});


define('ksc-breakpoint-notifier/events',["komplett-pubsub"], function (pubsub) {

    var events = {
        isXs: function (isXs){},
        isSm: function (isSm){},
        isMd: function (isMd){},
        isLg: function (isLg) { },
        screenSizeChanged: function(size) {}
    };

    return pubsub.extend(events);

});


define("ksc-breakpoint-notifier/ViewModel", ["knockout", "ksc-breakpoint-notifier/events", "ksc-breakpoint-notifier/ScreenSize"], function (ko, events, ScreenSize) {

    function ViewModel(initObject) {

        var self = this;

        var states = {xs: "xs",sm: "sm",md: "md",lg: "lg"},
            screenSize = new ScreenSize(initObject.fullResponsive);

        var currentScreenSize = function () {
            if (screenSize.isLg()) {
                return states.lg;
            } else if (screenSize.isMd()) {
                return states.md;
            } else if (screenSize.isSm()) {
                return states.sm;
            } else {
                return states.xs;
            }
        };

        this.state = ko.observable(currentScreenSize());

        var publishObservable = {
            lg: ko.observable(),
            md: ko.observable(),
            sm: ko.observable(),
            xs: ko.observable()
        };

        publishObservable.lg.subscribe(function(value) {
            events.isLg.publish(value);
        });
        publishObservable.md.subscribe(function (value) {
            events.isMd.publish(value);
        });
        publishObservable.sm.subscribe(function (value) {
            events.isSm.publish(value);
        });
        publishObservable.xs.subscribe(function (value) {
            events.isXs.publish(value);
        });
        events.screenSizeChanged.isSubscribedTo(function (trigger) {
            trigger(self.state(), false);
        });
        self.state.subscribe(function(value) {
            events.screenSizeChanged.publish(value);
        });

        var publishEvents = function(currentState) {
            if (currentState == states.lg) {
                publishObservable.lg(true);
                publishObservable.md(false);
                publishObservable.sm(false);
                publishObservable.xs(false);
            } else if (currentState == states.md) {
                publishObservable.lg(false);
                publishObservable.md(true);
                publishObservable.sm(false);
                publishObservable.xs(false);
            } else if (currentState == states.sm) {
                publishObservable.lg(false);
                publishObservable.md(false);
                publishObservable.sm(true);
                publishObservable.xs(false);
            } else {
                publishObservable.lg(false);
                publishObservable.md(false);
                publishObservable.sm(false);
                publishObservable.xs(true);
            }
        };

        self.state.subscribe(publishEvents);

        (function init() {

            publishEvents(self.state());

            events.isLg.isSubscribedTo(function (notify) {
                if (self.state() == states.lg) {
                    notify(true);
                } else {
                    notify(false);
                }
                
            });

            events.isMd.isSubscribedTo(function (notify) {
                if (self.state() == states.md) {
                    notify(true);
                } else {
                    notify(false);
                }
            });

            events.isSm.isSubscribedTo(function (notify) {
                if (self.state() == states.sm) {
                    notify(true);
                } else {
                    notify(false);
                }
            });

            events.isXs.isSubscribedTo(function (notify) {
                if (self.state() == states.xs) {
                    notify(true);
                } else {
                    notify(false);
                }
            });

            window.addEventListener("resize", function() {
                self.state(currentScreenSize());
            });

        })();
    }

    return ViewModel;
});


define('ksc-breakpoint-notifier',["ksc-breakpoint-notifier/ViewModel","ksc-breakpoint-notifier/events"], function (BreakpointNotifier, events) {
    
    function init(options){
        
        var options = {
            fullResponsive : options.fullResponsive === undefined ? false : options.fullResponsive
        };
        
        new BreakpointNotifier(options);
    }

    return {
        init: init,
        events: events
    }
    
});

