define(["querystring"], function (querystring) {

    function extractClickThroughLink (link) {
        var queryData = link.split("?")[1];
        return querystring.parse(queryData).ct;
    };

    function removeLinkParameterContent (link, parameterToRemove) {
        var re = RegExp(parameterToRemove + "=.*&?");
        return link.replace(re, parameterToRemove);
    };

    function setTargetAndReportUrls (rrPromos) {
        rrPromos.forEach(function (promo) {
            promo.targetUrl = extractClickThroughLink(promo.destination_URL);
            promo.reportClickUrl = removeLinkParameterContent(promo.destination_URL, "ct");
        });
    };

    return {
        setTargetAndReportUrls: setTargetAndReportUrls
    };
});